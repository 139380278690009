import commonHelper from '@/app/utility/common.helper.utility';

export default {
  name: 'taxRule',
  components: {},
  watch: {
    currentPage: function() {
      this.getTaxRuleList();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getTaxRuleList();
    }
  },
  data() {
    return {
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      currentPage: 1,
      payload: {},
      totalRows: null,
      taxRuleSearch: '',
      data: [],
      fields: [
        {
          key: 'tax_determinant'
        },
        {
          key: 'tax_rule_type'
        },
        {
          key: 'tax_type_value',
        },
        {
          key: 'tax_rule_name'
        },
        {
          key: 'tax_type_group_value'
        },
        {
          key: 'org_type_lookup'
        },
        {
          key: 'last_update_date'
        }
      ]
    };
  },
  mounted() {
  },
  methods: {
    getTaxRuleList() {
      this.payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage
      };
      this.$store
        .dispatch('taxRule/getTaxRuleList', this.payload)
        .then(response => {
          if (response.status === 200) {
            this.data = response.data.data.page;
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
         alert("Something Went Wrong")
        });
    },
    clearTaxRule() {
      this.data=null;
      this.totalRows=null;
      this.taxRuleSearch = null;
    }
  },
};
